import * as React from 'react'
import { cn } from '~/utils/cn'
import { Icon } from '../../icon'
import { iconButtonClassName } from '../../icon/icon.styles'
import { DefaultInfoIcon, Tooltip, TooltipProvider } from '../../tooltip'
// Import the Tooltip component
import { Caption, CaptionColor } from '../../typography/components/caption'
import { inputClassName } from './input.styles'
import { InputAdornmentKind, InputProps } from './input.types'

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      label,
      error,
      helpMessage,
      wordCount,
      startAdornment,
      endAdornment,
      disabled,
      small,
      medium,
      setValue,
      tooltip, // Add tooltip prop
      disableNegative,
      inputClassName: inputClass,
      children,
      ...props
    },
    ref,
  ) => {
    const {
      textAuxStyles,
      labelStyles,
      inputContainerStyles,
      inputStyles,
      iconStyle,
      startAdornmentStyles,
      endAdornmentStyles,
    } = inputClassName({
      error: !!error,
      disabled,
      small: !!small,
      medium: !!medium,
      startAdornment: !!startAdornment,
      endAdornment: !!endAdornment,
    })

    return (
      <div className={cn(className, 'w-full')}>
        <TooltipProvider>
          <div className='flex items-center gap-1'>
            <label
              className={labelStyles()}
              htmlFor={props.id}
              aria-label={label}
            >
              {label}
            </label>

            {tooltip && (
              <Tooltip
                content={tooltip}
                tooltipTrigger={<DefaultInfoIcon />}
              />
            )}
          </div>
        </TooltipProvider>
        <div className={inputContainerStyles()}>
          {!!startAdornment && (
            <div className={startAdornmentStyles()}>
              {startAdornment.kind === InputAdornmentKind.normal ? (
                <Icon
                  icon={startAdornment.icon}
                  className={iconStyle()}
                />
              ) : (
                <button
                  type='button'
                  aria-label='startAdornment'
                  className={iconButtonClassName}
                  {...endAdornment}
                >
                  <Icon
                    icon={startAdornment.icon}
                    className={iconStyle()}
                  />
                </button>
              )}
            </div>
          )}
          <input
            type={type}
            min={disableNegative && type === 'number' ? 0 : undefined}
            className={inputStyles({
              startAdornment: !!startAdornment,
              endAdornment: !!endAdornment,
              class: inputClass,
            })}
            ref={ref}
            disabled={disabled}
            onChange={(e) => setValue?.(props.name ?? '', e.target.value)}
            aria-label={props['aria-label'] ?? props.placeholder}
            {...props}
          />
          {!!endAdornment && (
            <div className={endAdornmentStyles()}>
              {endAdornment.kind === InputAdornmentKind.normal ? (
                <Icon
                  icon={endAdornment.icon}
                  className={iconStyle()}
                />
              ) : (
                <button
                  type='button'
                  aria-label='endAdornment'
                  className={cn(iconButtonClassName, endAdornment.className)}
                  {...endAdornment}
                >
                  <Icon
                    icon={endAdornment.icon}
                    className={iconStyle()}
                  />
                </button>
              )}
            </div>
          )}
        </div>
        {children}
        <div className='flex justify-between'>
          <div>
            {error && error.trim().length > 0 ? (
              <span className='mt-1 inline-block text-xs text-status-critical'>{error}</span>
            ) : (
              helpMessage && <Caption color={CaptionColor.secondary}>{helpMessage}</Caption>
            )}
          </div>
          <div>
            {wordCount && (
              <span className={textAuxStyles()}>
                {(props.value as string)?.length}/{wordCount}
              </span>
            )}
          </div>
        </div>
      </div>
    )
  },
)

Input.displayName = 'Input'

export { Input }
