'use client'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { forwardRef } from 'react'
import { cn } from '~/utils/cn'
import { TooltipProps } from './tooltip.props'

const TooltipProvider = TooltipPrimitive.Provider

const TooltipRoot = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border border-borders-1 bg-neutral-10 px-2 py-1 text-xs text-typography-high-contrast drop-shadow-sm ',
      className,
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

const Tooltip: React.FC<TooltipProps> = (props) => {
  return (
    <TooltipRoot delayDuration={props.delayDuration ?? 0}>
      <TooltipTrigger
        data-testid='tooltip-trigger'
        type='button'
        asChild={props.triggerAsChild}
      >
        {props.tooltipTrigger}
      </TooltipTrigger>

      <TooltipContent className={props.tooltipContentClassName}>{props.content}</TooltipContent>
    </TooltipRoot>
  )
}

interface DefaultInfoIconProps {
  className?: string
}

const DefaultInfoIcon = ({ className }: DefaultInfoIconProps) => (
  <i className={cn('fa-solid fa-circle-info text-inherit', className)} />
)

export { DefaultInfoIcon, Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
